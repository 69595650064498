<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-2 py-lg-3 pt-lg-4">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <img src="logod.png" height="125">
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon class="fill-default" points="2560 0 2560 100 0 100" />
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-header bg-transparent pb-5">
              <div class="text-muted text-center mt-2 mb-3">
                <small>Sign in with</small>
              </div>
              <div class="btn-wrapper text-center">
                <a class="btn btn-neutral btn-icon" @click="googleLogin">
                  <span class="btn-inner--icon">
                    <img src="~/static/img/icons/common/google.svg">
                  </span>
                  <span class="btn-inner--text"> Google </span>
                </a>
              </div>
            </div>
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>Or sign in with credentials</small>
              </div>
              <validation-observer
                v-slot="{ handleSubmit }"
                ref="formValidator"
              >
                <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input
                    v-model="email"
                    alternative
                    class="mb-3"
                    name="Email"
                    :rules="{ required: true, email: true }"
                    prepend-icon="ni ni-email-83"
                    placeholder="Email"
                  />

                  <base-input
                    v-model="password"
                    alternative
                    class="mb-3"
                    name="Password"
                    :rules="{ required: true, min: 6 }"
                    prepend-icon="ni ni-lock-circle-open"
                    type="password"
                    placeholder="Password"
                  />

                  <base-checkbox v-model="rememberMe">
                    Remember me
                  </base-checkbox>
                  <div class="text-center">
                    <base-button
                      type="primary"
                      native-type="submit"
                      class="my-4"
                    >
                      Sign in
                    </base-button>
                  </div>
                </form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  layout: "LoginLayout",
  data() {
    return {
      email: "",
      password: "",
      rememberMe: false,
    }
  },
  async mounted() {
    const isIt = await this.$store.dispatch("user/isLoggedIn")
    if (isIt) this.$router.push("dashboard")
  },
  methods: {
    onSubmit() {
      this.login()
    },
    async googleLogin() {
      this.loading = true
      try {
        await this.$store.dispatch("user/loginWithGoogle")
        this.$router.push("dashboard")
      } catch (e) {
        this.loading = false
        console.error(e.message)
      }
    },
    async login() {
      this.loading = true
      try {
        await this.$store.dispatch("user/login", {
          email: this.email,
          password: this.password,
        })
        this.$router.push("dashboard")
      } catch (e) {
        this.loading = false
        console.error(e.message)
      }
    },
  },
}
</script>
